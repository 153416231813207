<template>
  <div
    v-if="arrowDistancesState.showArrows && (showLeft || showMiddle || showRight)"
    class="arrow-distances-wrapper"
    :style="{transform: transformCoef, 'transform-origin': 'bottom center'}"
  >
    <div :class="['arrow-container-left', {'hidden': !showLeft}]">
      <div
        v-if="showLeftText"
        class="arrow-text"
      >
        {{ arrowDistancesState.leftValue }}m
      </div>
      <div :class="['arrow-image', `arrow-${arrowDistancesState.leftType}`]" />
    </div>
    <div :class="['arrow-container-middle', {'hidden': !showMiddle}]">
      <div
        v-if="showMiddleText"
        class="arrow-text"
      >
        {{ arrowDistancesState.middleValue }}m
      </div>
      <div :class="['arrow-image', `arrow-${arrowDistancesState.middleType}`]" />
    </div>
    <div :class="['arrow-container-right', {'hidden': !showRight}]">
      <div
        v-if="showRightText"
        class="arrow-text"
      >
        {{ arrowDistancesState.rightValue }}m
      </div>
      <div :class="['arrow-image', `arrow-${arrowDistancesState.rightType}`]" />
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */

import { defineComponent } from 'vue'
import { WindowAspect } from '@powerplay/core-minigames-ui-ssm'
import { ArrowTypes } from '@/app/types'
import { arrowDistancesState } from '@/stores'

export default defineComponent({
  name: 'ArrowDistances',
  mixins: [WindowAspect],
  data() {

    return {
      arrowDistancesState: arrowDistancesState()
    }

  },
  computed: {
    transformCoef() {

      return `scale(${this.scaleCoef})`

    },
    showLeft() {

      return arrowDistancesState().leftType !== ArrowTypes.none && arrowDistancesState().leftValue >= arrowDistancesState().limitToShow

    },
    showLeftText() {

      return arrowDistancesState().leftType === ArrowTypes.green

    },
    showMiddle() {

      return arrowDistancesState().middleType !== ArrowTypes.none && arrowDistancesState().middleValue >= arrowDistancesState().limitToShow

    },
    showMiddleText() {

      return arrowDistancesState().middleType === ArrowTypes.green

    },
    showRight() {

      return arrowDistancesState().rightType !== ArrowTypes.none && arrowDistancesState().rightValue >= arrowDistancesState().limitToShow

    },
    showRightText() {

      return arrowDistancesState().rightType === ArrowTypes.green

    },
  }
})

</script>

<style lang="less" scoped>

.arrow-distances-wrapper {

  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;

  .hidden {
    visibility: hidden;
  }

  .arrow-container-left {

    position: relative;

    .arrow-image {
      transform: rotate(10deg);
    }

    .arrow-text {
      top: -32px;
      left: 44px;
      text-align: left;
    }

  }

  .arrow-container-middle {

    position: relative;

    .arrow-text {
      top: -35px;
      left: -11px;
      text-align: center;
    }

  }

  .arrow-container-right {

    position: relative;

    .arrow-image {
      transform: rotate(-10deg);
    }

    .arrow-text {
      top: -32px;
      left: -66px;
      text-align: right;
    }

  }

  .arrow-text {
    text-shadow: 2.1px 2.1px 2px rgba(0, 0, 0, 0.71);
    font-family: Nunito Sans;
    font-size: 36px;
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: absolute;
    width: 100px;
  }

  .arrow-image {

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 80px;
    height: 80px;

    &.arrow-green {
      background-image: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/keirin/ui/arrows/green.png');
    }
    &.arrow-red {
      background-image: url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/keirin/ui/arrows/red.png');
    }

  }

}

</style>
